import axios from "axios";
import { getToken } from "./auth";
//LOCAL SERVER
      //  const baseURL = "http://localhost:8000/api";

//  const baseURL = "http://localhost/providers_portal_laravel/api";


//UAT SERVER
 const baseURL = "https://test-backend-providers.bettercare.tech/api";


//LIVE SERVER
// const baseURL = "https://provbackend.bettercare.tech/api";
 
//  const baseURL = "https://provtest.bettercare.co.mz/api";


const api = axios.create({
	baseURL,
	withCredentials: true,
});

api.interceptors.request.use(async (config) => {
	const token = getToken();
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	return config;
});

export default api;
